import React from 'react';
import { stringify } from 'rebem-classname';
import SelectorCapacidadContenido from './SelectorCapacidadContenido.jsx';

export default function SelectoresCapacidad(props)
{
	const block = 'SelectoresCapacidad';
	return(
		<div className={stringify({block})}>
			<SelectorCapacidadContenido
				onPlus={props.onPlus}
				onMinus={props.onMinus}
				capacidades={props.capacidades}
				toggle={props.toggle}
			/>
			<div className={stringify({block, elem:'links'})}>
				<button type="button" className={`btn btn-noButton ${stringify({block,elem:'borrar'})}`} onClick={props.onRestablecerCapacidad}>Reestablecer</button>
				<button type="button" className={`btn btn-noButton ${stringify({block,elem:'listo'})}`} onClick={props.closeContainerCapacidad}>Listo</button>					                	
			</div>
		</div>
	);
}