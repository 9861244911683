
export default class TextoBuscadorHelper {
    static getTextoCuantos(bebes, niños, adolescentes, jovenes, adultos) {
        const cantidad = niños + adolescentes + jovenes + adultos;

        if (!cantidad && !bebes) {
            return 'cuántos somos?';
        }

        let textoCantidad = '';
        if (cantidad) {
            textoCantidad = cantidad + (cantidad <= 1 ? ' persona' : ' personas');
        } else if (!cantidad && bebes) {
            textoCantidad = '1 persona';
        }

        let textoBebes = '';
        if (bebes) {
            textoBebes = ', ' + bebes + (bebes <= 1 ? ' bebé' : ' bebes');
        }
        return textoCantidad + textoBebes;
    }

    static getTextoCuando(mStartDate, mEndDate, conector = ' - ', formato = "D MMMM") {
        if (mStartDate && mEndDate) {
            return mStartDate.format(formato) + conector + mEndDate.format(formato);
        }
        return 'cuándo?';
    }
}