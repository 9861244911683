import React from 'react';
import { Tooltip, OverlayTrigger, ButtonToolbar } from 'react-bootstrap';
import { FaMapMarkerAlt, FaCalendarAlt, FaUser } from 'react-icons/fa';
import ClickOutside from 'react-click-outside';
import { DateRangePicker } from 'react-dates';
import AutocompleteLugares from '../Commons/AutocompleteLugares';
import SelectoresCapacidad from '../Commons/SelectoresCapacidad';
import DropdownBuscadorListado from './DropdownBuscadorListado';
import TextoBuscadorHelper from "../../helpers/TextoBuscadorHelper";
import moment from 'moment';
import { stringify } from 'rebem-classname';
moment.locale('es');

export default class BuscadorListadoDeskop extends React.Component{
	constructor(props) {
		super(props);
		//AutoComplete
		this.onLugarSelected = this.onLugarSelected.bind(this);
		//FECHAS
		this.onDatePickerFocusChange = this.onDatePickerFocusChange.bind(this);
		this.showCalendar = this.showCalendar.bind(this);
		this.ocultarCalendario = this.ocultarCalendario.bind(this);
		this.onClickOutsideCalendar = this.onClickOutsideCalendar.bind(this);
		//Capacidad
		this.showContainerCapacidad = this.showContainerCapacidad.bind(this);
		this.closeContainerCapacidad = this.closeContainerCapacidad.bind(this);
		this.tieneCapacidadDefinida = this.tieneCapacidadDefinida.bind(this);
		this.sumarCapacidad = this.sumarCapacidad.bind(this);

		this.state = {
			//Fechas
			isCalendarVisible:false,
			focusedInput:'startDate',
			//Capacidad
			isContainerCapacidadVisible:false,
			showTooltipFecha: !localStorage.getItem('tooltipFecha')
		}
	}

	componentDidMount(){
		let cookieTooltipFecha = localStorage.getItem('tooltipFecha');
		if (!cookieTooltipFecha) {
			localStorage.setItem('tooltipFecha', true);
		}
	}

	onDatePickerFocusChange(focusedInput) {
		//si ya selecciono las dos fechas y no cargo datos en el selector de capacidad abro el mismo
		if (!focusedInput && this.sumarCapacidad() == 0) {
			this.showContainerCapacidad();
		}
		this.setState({ focusedInput });
	}

	showCalendar() {
		this.setState({ 
			isCalendarVisible: true
		});
	}

	ocultarCalendario(){
		this.setState({
			isCalendarVisible:false
		});
	}

	onClickOutsideCalendar() {
		if(this.props.startDate || this.props.endDate){
			return;
		}

		this.setState({
			isCalendarVisible: false
		});
	}

	//AutocompleteLugares
	onLugarSelected(...args){
		this.props.onLugarSelected(...args);
		//una vez que llenas el autocomplete continuas con el calendario
		//en caso de que tenga datos continua con el selector de capacidad
		if (!this.state.focusedInput && this.tieneCapacidadDefinida()) {
			this.showContainerCapacidad();
		} else {
			if (!this.props.startDate || !this.props.endDate) {
				this.showCalendar();
			}
		}
	}

	//Capacidad
	showContainerCapacidad() {
		this.setState({
			isContainerCapacidadVisible: true
		});
	}

	closeContainerCapacidad(){
		this.setState({ 
			isContainerCapacidadVisible: false
		});	
	}

	tieneCapacidadDefinida(){
		return this.sumarCapacidad() != 0;
	}
	sumarCapacidad(){
		let contPersonas =
		this.props.niños +
		this.props.adolescentes +
		this.props.jovenes +
		this.props.adultos;

		return contPersonas;
	}

	render(){
		const {
			isContainerCapacidadVisible,
			focusedInput,
			isCalendarVisible,
			showTooltipFecha
		} = this.state;
		const {
			adultos,
			adolescentes,
			jovenes,
			bebes,
			niños,
			mascotas,
			onPlus,
			onMinus,
			toggleMascotas,
			onRestablecerCapacidad, 
			lugar,
			localidadSinLugar,
			listadoCustom,
			onCapacidadChange,
			onDatesChange,
			busquedasRecientes,
			onLugarClear,
			areaDeEfecto,
			onAreaDeEfectoSelected,
			startDate,
			endDate,
			onBuscar,
		} = this.props;

		if(listadoCustom){
			return null;
		}

		let containerCapacidad = null;
		if(isContainerCapacidadVisible){
			containerCapacidad = <SelectoresCapacidad
				onPlus={onPlus}
				onMinus={onMinus}
				onRestablecerCapacidad={onRestablecerCapacidad}
				capacidades={{
					adultos,
					jovenes,
					adolescentes,
					niños,
					bebes,
					mascotas,
				}}
				toggle={toggleMascotas}
				closeContainerCapacidad={this.closeContainerCapacidad}
			/>;
		}

		const block='DesktopListado';
		const tooltipFecha = <Tooltip id="tooltip" className="DesktopListado__tooltipFecha">
			<strong>Para mejorar los resultados de tu búsqueda, completá las fechas de tu viaje.</strong>
		</Tooltip>;

		const textCuando = TextoBuscadorHelper.getTextoCuando(this.props.startDate, this.props.endDate, ' al ', 'DD/MM/YY');

		return(
			<div className={`container-fluid ${stringify({block})}`}>
				<div className={stringify({block, elem:"contenedor-buscador"})}>
					<div className={stringify({block, elem:"icono-input", mods:{gly:true}})}>
						<FaMapMarkerAlt />
					</div>
					<div className={stringify({block, elem:"texto-input"})}>Ubicación</div>
					
					<AutocompleteLugares 
						conIconos
						key={lugar ? lugar.nombre : 'buscar'}
						onCapacidadChange={onCapacidadChange}
						onDatesChange={onDatesChange}
						lugar={lugar || localidadSinLugar}
						busquedasRecientes={busquedasRecientes}
						onLugarClear={onLugarClear}
						onLugarSelected={this.onLugarSelected}
					/>

					<div className={stringify({block, elem:"distancia"})}>
						<DropdownBuscadorListado
							textoPorDefecto="Hasta 10 km"
							areaDeEfecto={areaDeEfecto}
							onAreaDeEfectoSelected={onAreaDeEfectoSelected}
							version={'desktop'}
						/>
					</div>
						<ClickOutside onClickOutside={this.onClickOutsideCalendar}>
							{isCalendarVisible ?
								<div className={stringify({block, elem:"contenedor-fecha", mods:{gly:true}})}>
									<DateRangePicker
										startDate={startDate}
										endDate={endDate} 
										onClose={this.ocultarCalendario}
										onDatesChange={onDatesChange}
										focusedInput={focusedInput} 
										onFocusChange={this.onDatePickerFocusChange} 
										endDatePlaceholderText={"Salida"}
										startDatePlaceholderText={"Llegada"}
										customArrowIcon={"–"}
										hideKeyboardShortcutsPanel={true}
										showDefaultInputIcon={false}
										customInputIcon={<FaCalendarAlt />} 
									/>
								</div>
								:
								<div className={stringify({block, elem:"contenedor-fecha", mods:{gly:true}})}>
									{(!startDate && showTooltipFecha) || (!endDate && showTooltipFecha) ? <ButtonToolbar>
										<OverlayTrigger 
											placement="bottom" 
											overlay={tooltipFecha}
											defaultOverlayShown
											rootClose
										>
											<button className={`btn btn-default" ${stringify({block,elem:"fecha"})}`} onClick={this.showCalendar}>
												<FaCalendarAlt /> 
												<div className={stringify({block, elem:"contenido"})}> 
													<div className={stringify({block, elem:"titulo"})}>Entrada / Salida</div> 
													<div className={stringify({block, elem:"valor"})}>{textCuando}</div> 
												</div> 
											</button>
										</OverlayTrigger>
									</ButtonToolbar>
									:
									<button className={`btn btn-default ${stringify({block,elem:"fecha"})}`} onClick={this.showCalendar}>
										<FaCalendarAlt /> 
										<div className={stringify({block, elem:"contenido"})}> 
											<div className={stringify({block, elem:"titulo"})}>Entrada / Salida</div> 
											<div className={stringify({block, elem:"valor"})}>{textCuando}</div> 
										</div> 
									</button>
								}
								</div>
							}
						</ClickOutside>
					<div className={stringify({block, elem:"contenedor-capacidad"})}>
						<ClickOutside onClickOutside={this.closeContainerCapacidad}>
							<button className={`btn btn-default ${stringify({block, elem:"capacidad", mods:{gly:true}})}`} onClick={this.showContainerCapacidad}> 
								<FaUser /> 
								<div className={stringify({block, elem:"contenido"})}> 
								  <div className={stringify({block, elem:"titulo"})}>Personas</div> 
									<div className={stringify({block, elem:"valor"})}>{TextoBuscadorHelper.getTextoCuantos(bebes, niños, adolescentes, jovenes, adultos)}</div> 
								</div>
							</button>
							{containerCapacidad}
						</ClickOutside>
					</div>
					<button className={`btn btn-naranja ${stringify({block, elem:"buscar"})}`} onClick={onBuscar}>Buscar</button>
				</div>
			</div>
		);
	}
}